import dateFormat from 'dateformat';

const { Builder: xmlBuilder } = require('xml2js');
import { v4 as uuid } from 'uuid';

const getLocationObject = location => {
    if (!location) return;

    return {
        'ns3:locationMRN': location.URN,
    };
};

const buildLocationState = data => {
    const payload = {
        $: {
            'xsi:type': 'ns2:LocationState',
            'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        },
        'ns2:referenceObject': data.referenceObject,
        'ns2:time': data.stateTime,
        'ns2:timeType': data.timeType.toUpperCase(),
    };

    let timeSequence = '';
    if (data.timeSequence === 'ARRIVAL_TO') {
        timeSequence = 'ns2:arrivalLocation';
    } else if (data.timeSequence === 'DEPARTURE_FROM') {
        timeSequence = 'ns2:departureLocation';
    }

    payload[timeSequence] = {};
    const toLocation = getLocationObject(data.to);
    const fromLocation = getLocationObject(data.from);
    if (fromLocation) {
        payload[timeSequence]['ns2:from'] = fromLocation;
    }
    if (toLocation) {
        payload[timeSequence]['ns2:to'] = toLocation;
    }
    return payload;
};

const buildServiceState = data => {
    const payload = {
        $: {
            'xsi:type': 'ns2:' + data.stateType,
            'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        },
        'ns2:serviceObject': data.serviceObject,
        'ns2:timeSequence': data.timeSequence,
        'ns2:time': data.stateTime,
    };

    if (data.stateType !== 'AdministrationState') {
        payload['ns2:timeType'] = data.timeType.toUpperCase();
    }

    if (data.serviceType === 'STATIONARY') {
        payload['ns2:at'] = getLocationObject(data.at);
    } else if (data.serviceType === 'NAUTICAL') {
        payload['ns2:between'] = {
            'ns2:to': getLocationObject(data.to),
            'ns2:from': getLocationObject(data.from),
        };
    }
    return payload;
};

export default function buildPortCallMessage(data, isXmlFormat = false) {
    if (isXmlFormat) {
        const portCallMessageScheme = 'urn:mrn:stm:schema:port-call-message:0.6.1';
        const rootMessageModel = {
            $: {
                xmlns: portCallMessageScheme,
                'xmlns:ns2': `${portCallMessageScheme}:payload`,
                'xmlns:ns3': `${portCallMessageScheme}:entity`,
            },
            portCallId: data.portCallId,
            vesselId: `urn:mrn:stm:vessel:imo:${data.vesselId}`,
            messageId: `urn:mrn:stm:portcdm:message:${uuid()}`,
            reportedAt: new Date().toISOString(),
            comment: data.comment,
        };

        if (data.stateType === 'LocationState') {
            rootMessageModel.payload = buildLocationState(data);
        } else {
            rootMessageModel.payload = buildServiceState(data);
        }

        const builder = new xmlBuilder();

        return builder.buildObject({ portCallMessage: rootMessageModel });
    } else {
        let state = {
            messageId: `urn:mrn:stm:portcdm:message:${uuid()}`,
            portcallId: data.portCallId,
            vesselId: data.vesselId,
            stateDefinition: data.stateId,
            movement: {
                at: data.at && data.at.URN,
                from: data.from && data.from.URN,
                to: data.to && data.to.URN,
            },
            comment: data.comment,
            time: dateFormat(data.stateTime, 'isoDate') + ' ' + dateFormat(data.stateTime, 'isoTime'),
            timeType: data.timeType,
            reportedBy: data.reportedBy,
        };

        return state;
    }
}
