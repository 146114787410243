const { Builder: xmlBuilder } = require('xml2js');
import { v4 as uuid } from 'uuid';

export default function withdrawnPortCallMessage(data) {
    const portCallMessageScheme = 'urn:mrn:stm:schema:port-call-message:0.6.1';
    const rootMessageModel = {
        $: {
            xmlns: portCallMessageScheme,
            'xmlns:ns2': `${portCallMessageScheme}:payload`,
            'xmlns:ns3': `${portCallMessageScheme}:entity`,
        },
        portCallId: data.portCallId,
        vesselId: `urn:mrn:stm:vessel:imo:${data.vesselId}`,
        messageId: `urn:mrn:stm:portcdm:message:${uuid()}`,
        payload: {
            $: {
                'xsi:type': 'ns2:MessageOperation',
                'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
            },
            'ns2:operation': 'WITHDRAW',
            'ns2:messageId': data.messageId,
        },
    };

    const builder = new xmlBuilder();

    return builder.buildObject({ portCallMessage: rootMessageModel });
}
